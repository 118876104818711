<template>
  <!-- Roles Sub Nav -->
  <b-container fluid>
    <b-row no-gutters>
      <b-col>
        <router-link class="h6 font-weight-bold" to="/" tag="a"
          >← Back to roles</router-link
        >
      </b-col>
    </b-row>
  </b-container>
</template>

<script>
export default {
  name: "BackToRoles",
};
</script>
<style scoped>
a {
  color: black !important;
}
</style>
