<template>
  <!-- Owner -->
  <div>
    <b-container fluid>
      <Nav></Nav>
      <BackToRoles></BackToRoles>
    </b-container>
  </div>
</template>

<script>
import Nav from "../components/Nav.vue";
import BackToRoles from "../components/BackToRoles.vue";

export default {
  name: "Roles",
  components: {
    Nav,
    BackToRoles
  }
};
</script>
